import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useArticleQuery = () => {
  const { allSanityArticle } = useStaticQuery(graphql`
    query {
      allSanityArticle {
        articles: nodes {
          title
          excerpt
          slug {
            current
          }
          featuredImage {
            ...ImageWithPreview
            # asset {
            #   url
            # }
          }
          content {
            ...PageModules
          }
        }
      }
    }
  `);
  return allSanityArticle || {};
};
